import React, { useMemo } from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useResponsive } from '../../../hooks/useResponsive';
import {
  doNothing,
  possesiveAdaptationI18n,
} from '../../../services/utils';

import { POWER_UPS_RELATION, WELLNESS_MULTIMODAL_ID }
from '../../changeCoverage/powerups/powerups.constans';

import FigoLoadingOverlay from '../../common/FigoLoadingOverlay';

import { PowerupCard }
from '../../changeCoverage/changeCoveragePreview/PowerupCard';
import { ChangeCoverageDetails } from './ChangeCoverageDetails';
import { ModifierModalInfo } from '../modifiers/ModifierModalInfo';
import { ChangeCoverageButtons } from './ChangeCoverageButtons';
import { useCoverageModal } from './hooks/useCoverageModal';

import './css/ChangeCoverageScreenPreview.css';

const {
  VETERINARY_EXAM_FEES_PSM_ID,
  WELLNESS_PSM_ID,
} = POWER_UPS_RELATION;

const ChangeCoverageScreenPreview = ({
  onChangeCoverageClick = () => { },
  onKeepClick = () => { },
}) => {
  const { t } = useTranslation('changeCoverage');
  const { isTablet, isMobile } = useResponsive();
  const {
    policies: {
      changeCoverage: {
        loading,
        preview,
      },
    },
  } = useSelector(({ policies }) => ({ policies }));
  const {
    handlePetProtectCopayInfoClick,
    handleVetFeesClick,
    handleWellnessClick,
    multiModalCategoryId,
    multimodalDataSelected,
    multiModalVisible,
    setMultiModalVisible,
  } = useCoverageModal();

  const powerups = useMemo(() => [
    {
      detail: preview.VeterinaryInformation,
      id: VETERINARY_EXAM_FEES_PSM_ID,
      included: preview.HaveVeterinaryFees,
      title: t('preview.vetFees'),
    },
    {
      detail: preview.WellnessInformation,
      id: WELLNESS_PSM_ID,
      included: preview.HaveWellness,
      title: t('preview.wellness'),
    },
  ], [preview]);

  const handlePowerupClick = (id) => () => {
    switch (id) {
      case VETERINARY_EXAM_FEES_PSM_ID:
        handleVetFeesClick(id);
        break;

      case WELLNESS_PSM_ID:
        handleWellnessClick(WELLNESS_MULTIMODAL_ID.BASIC);
        break;
      default:
        doNothing();
    }
  };

  function formatDate(date) {
    return !date ? '-' : moment(date.split('T')[0]).format('MM/DD/YYYY');
  }

  function handleCloseMultiModal() {
    setMultiModalVisible(false);
  }

  return (
    <div className="Change-coverage-screen-preview-container">
      <h3>
        {`${isTablet || isMobile
          ? t('preview.mTitle', {
            count: possesiveAdaptationI18n(preview.PetName),
            name: preview.PetName,
          })
          : t('preview.title', {
            count: possesiveAdaptationI18n(preview.PetName),
            name: preview.PetName,
          })}`}
      </h3>

      <ChangeCoverageDetails
        annualBenefit={preview.AnnualBenefit}
        deductible={preview.Deductible}
        effectiveDate={formatDate(preview.NewPremiumEffectiveDate)}
        lastDate={formatDate(preview.LastDateToChange)}
        petName={preview.PetName}
        plan={preview.PlanName}
        price={preview.Premium}
        reimbursement={preview.Reimbursement}
      />

      <div className="Change-coverage-preview-pet-protect-container">
        <PowerupCard
          detail={preview.PerIncidentCopayInformation}
          handleLearnMoreClick={handlePetProtectCopayInfoClick}
          included={preview.HavePerIncidentCopay}
          title={t('preview.petProtectCopayTitle')}
        />
      </div>

      <h3>{t('powerups.Powerups')}</h3>

      <p className="Change-coverage-preview-powerup-description">
        {t('preview.powerupsText', {
          count: possesiveAdaptationI18n(preview.PetName),
          name: preview.PetName,
        })}
      </p>

      <div className="Change-coverage-preview-powerup-powerups-container">
        {powerups.map((powerup) => (
          <PowerupCard
            key={powerup.title}
            detail={powerup.detail}
            handleLearnMoreClick={handlePowerupClick(powerup.id)}
            included={powerup.included}
            title={powerup.title}
          />
        ))}
      </div>

      <h3>{t('preview.wantChanges')}</h3>

      <p className="Change-coverage-preview-want-changes-text">
        {t('preview.wantChangesPet', {
          beforeDate: formatDate(preview.LastDateToChange),
          interpolation: { escapeValue: false },
          petName: preview.PetName,
          renewalDate: formatDate(preview.NewPremiumEffectiveDate),
        })}
      </p>

      <ChangeCoverageButtons
        onClickPrimaryButton={onChangeCoverageClick}
        onClickSecondaryButton={onKeepClick}
        primaryText={t('preview.changeCoverage')}
        secondaryText={t('preview.back')}
      />

      <FigoLoadingOverlay visible={loading} />

      {multimodalDataSelected && (
        <ModifierModalInfo
          initialMenuSelected={multiModalCategoryId}
          isVisible={multiModalVisible}
          isWhatsCovered
          multiModalData={multimodalDataSelected}
          onClose={handleCloseMultiModal}
          t={t}
        />
      )}
    </div>
  );
};

export { ChangeCoverageScreenPreview };
