import { call, put, select } from 'redux-saga/effects';
import mixpanel from 'mixpanel-browser';

import {
  attemptPrivateToken,
  attemptTemporaryToken,
  bannerVisibleChanged,
  checkIfHasPrimaryVet,
  customerInformationFailed,
  customerInformationLoaded,
  forceLogout,
  loadDataForPrivateToken,
  loadMultiModalData,
  logIn,
  logOut,
  privateTokenCompleted,
  privateTokenFailed,
  saveImpersonateCustomerId,
  saveUserInfo,
  saveUserTermsOfService,
  sessionError,
  setPrivateToken,
  setTemporaryToken,
  showGenericError,
  startupFinished,
  temporaryTokenCompleted,
  temporaryTokenFailed,
  termsOfServiceAccepted,
} from '../actions';
import { POLICY_STATUS } from '../constants';
import {
  apiCallWithApiKey,
  get,
  getData,
  post,
  removeData,
  saveData,
} from '../services/api';
import {
  API_TOKEN,
  BANNER_PREFERENCES_KEY,
  CLAIMS_API_TOKEN,
  CUSTOMER_API_TOKEN,
  IMPERSONATE_EMAIL,
  IMPERSONATE_KEY,
  POLICY_API_TOKEN,
  USER_COMPANY_KEY,
  USER_INFO_KEY,
  WEBVIEW_API_TOKEN,
} from '../services/constants';
import app from '../app.json';
import { delay, maskPhoneNumber } from '../services/utils';
import { getPolicySummary } from './policies';
import {
  useCustomerApiForSagas,
  useCustomerApiV1ForSagas,
} from '../services/featureFlagsForSagas';
import { MIXPANEL_LOGIN_EVENTS } from '../services/mixpanel';
import { getApplicationMessages } from './common';

export function* loadCachedUserInfo() {
  const data = yield call(getData, USER_INFO_KEY);
  const userInfo = JSON.parse(data);

  if (userInfo) {
    yield put(saveUserInfo({
      ...userInfo,
      OtherPhoneNumber: userInfo.OtherPhoneNumber
        && maskPhoneNumber(userInfo.OtherPhoneNumber),
      PhoneNumber: maskPhoneNumber(userInfo.PhoneNumber),
    }));
  }
}

function* removeAllData() {
  yield call(removeData, USER_INFO_KEY);
  yield call(removeData, API_TOKEN);
  yield call(removeData, IMPERSONATE_KEY);
  yield call(removeData, BANNER_PREFERENCES_KEY);
  yield call(removeData, CUSTOMER_API_TOKEN);
  yield call(removeData, CLAIMS_API_TOKEN);
  yield call(removeData, POLICY_API_TOKEN);
  yield call(removeData, WEBVIEW_API_TOKEN);
}

export function* syncUserInformationStoreWithRedux() {
  const store = yield select(({ accountLogin, personalInformation }) => ({
    accountLogin,
    personalInformation,
  }));

  if (store.accountLogin.userInfo && store.personalInformation.userInfo) {
    const userInfo = {
      ...store.accountLogin.userInfo,
      ...store.personalInformation.userInfo,
    };

    yield call(saveData, USER_INFO_KEY, JSON.stringify(userInfo));
  }
}

export function* getUserInformation(dispatch) {
  const useCustomerApiV1 = yield useCustomerApiForSagas();
  const token = yield call(getData, 'token');
  let response = {};

  if (token) {
    const urlService = useCustomerApiV1 ? 'customer' : 'api';
    response = yield call(get, dispatch, `${urlService}/Account/personalInfo`);

    if (response.success && response.Data) {
      const personalInfoNullSafe = {
        ...response.Data,
        Address: {
          ...response.Data.Address,
          Line1: response.Data.Address.Line1 || '',
          Line2: response.Data.Address.Line2 || '',
          Phone1: response.Data.Address.Phone1 || '',
          Phone2: response.Data.Address.Phone2 || '',
        },
        OtherPhoneNumber: response.Data.OtherPhoneNumber || '',
        PhoneNumber: response.Data.PhoneNumber || '',
      };

      const personalInformation = {
        ...personalInfoNullSafe,
        OtherPhoneNumber: personalInfoNullSafe.OtherPhoneNumber
          && maskPhoneNumber(personalInfoNullSafe.OtherPhoneNumber),
        PhoneNumber: personalInfoNullSafe.PhoneNumber
          && maskPhoneNumber(personalInfoNullSafe.PhoneNumber),
      };

      yield put(saveUserInfo(personalInformation));
      yield call(saveData, USER_INFO_KEY, JSON.stringify(personalInfoNullSafe));
      yield call(saveData, USER_COMPANY_KEY,
        JSON.stringify(personalInfoNullSafe.Company));
      yield put(checkIfHasPrimaryVet());

      const impersonateEmail = getData(IMPERSONATE_EMAIL);

      if (impersonateEmail) {
        yield put(saveImpersonateCustomerId({
          adminEmail: impersonateEmail,
          customerId: personalInformation.CustomerGuid || '',
        }));
      }
    }
  }

  yield put(startupFinished());

  return response;
}

export function* getUserTermsOfService(dispatch) {
  const token = yield call(getData, 'token');
  let response = {};

  if (token) {
    response = yield call(get, dispatch, 'api/User/UserHasTermOfService');
    if (response.success && response.Data) {
      yield put(saveUserTermsOfService(response.Data));
    }
  }

  return response;
}

export function* loadCachedBannerVisible() {
  const data = yield call(getData, BANNER_PREFERENCES_KEY);
  const { visible } = JSON.parse(data) || { visible: true };
  yield put(bannerVisibleChanged({ visible: !!visible }));
}

export function* startUpApp(dispatch) {
  const token = yield call(getData, 'token');

  if (token) {
    yield call(loadCachedBannerVisible);
    yield call(getUserTermsOfService, dispatch);
    yield call(getPolicySummary, dispatch);
    yield call(getUserInformation, dispatch);
    yield call(getApplicationMessages, dispatch);
  } else {
    yield put(startupFinished());
  }
}

export function* doLogout(dispatch) {
  yield call(post, dispatch, 'api/Authentication/LogOut');
  yield put(logOut());
  yield call(removeAllData);
}

export async function createLogAuth({
  dataDogTag,
  error,
  httpStatus,
  mixpanelKey,
  email,
}) {
  if (mixpanelKey) {
    mixpanel.track(mixpanelKey, { email, error });
  }
  if (dataDogTag) {
    const urlLog = 'api/Log/LoginError';
    const userAgent = navigator.userAgent;
    const formattedError = `${dataDogTag} - ${error}`;

    const body = {
      Email: email,
      Error: formattedError,
      HttpStatusCode: httpStatus,
      OperatingSystem: 'petcloud-web',
      UserAgent: userAgent,
    };

    mixpanel.track(MIXPANEL_LOGIN_EVENTS.error, {
      email,
      error: formattedError,
    });
    await apiCallWithApiKey(urlLog, { body, method: 'POST' });
  }
}

function* handleB2CToken({
  accessToken = '',
  dispatch,
  errorMessage = '',
  success = true,
}) {
  try {
    if (success) {
      if (accessToken) {
        yield call(saveData, 'token', accessToken);

        yield call(createLogAuth, {
          mixpanel: MIXPANEL_LOGIN_EVENTS.getTerms,
        });

        const termOfService = yield call(getUserTermsOfService);

        if (termOfService?.success) {
          yield call(createLogAuth, {
            mixpanel: MIXPANEL_LOGIN_EVENTS.getTermsSuccess,
          });
        } else {
          yield call(createLogAuth, {
            dataDogTag: 'UserHasTermOfService',
            error: termOfService.message,
            httpStatus: termOfService.status || termOfService.statusCode,
            mixpanel: MIXPANEL_LOGIN_EVENTS.getTermsError,
          });

          throw new Error();
        }

        yield call(createLogAuth, {
          mixpanel: MIXPANEL_LOGIN_EVENTS.getPersonalInfo,
        });

        const personalInfo = yield call(getUserInformation, dispatch);

        if (personalInfo?.success) {
          yield call(createLogAuth, {
            mixpanel: MIXPANEL_LOGIN_EVENTS.getPersonalInfoSuccess,
          });
        } else {
          yield call(createLogAuth, {
            dataDogTag: 'personalInfo',
            error: personalInfo?.message,
            httpStatus: personalInfo?.status || personalInfo?.statusCode,
            mixpanel: MIXPANEL_LOGIN_EVENTS.getPersonalInfoError,
          });

          throw new Error();
        }

        const payload = { accessToken };

        yield put(logIn(payload));
      } else {
        yield put(sessionError(errorMessage));
      }
    } else {
      yield put(sessionError('An error has ocurred'));
    }
  } catch (error) {
    if (error?.message) {
      yield call(createLogAuth, {
        dataDogTag: 'handleB2CToken js',
        error: error.message,
        httpStatus: 1,
        mixpanel: MIXPANEL_LOGIN_EVENTS.error,
      });
    }

    yield put(sessionError('An error has ocurred'));
  }
}

export function* doLogin(action) {
  const { payload: { b2cToken, dispatch, email, isImpersonate } } = action;
  const url = '/api/Authentication/Token/RegisterB2C';

  const data = {
    AppVersion: app.version,
    Grant_Type: 'password',
    Impersonate: false,
    OsType: 'petcloud-web',
    Password: '',
  };

  yield call(saveData, 'token', b2cToken);

  yield call(createLogAuth, { mixpanel: MIXPANEL_LOGIN_EVENTS.registerB2C });
  const response = yield call(post, null, url, data);

  if (response.success) {
    yield call(createLogAuth, {
      mixpanel: MIXPANEL_LOGIN_EVENTS.registerB2CSuccess,
    });

    if (isImpersonate) {
      yield call(saveData, IMPERSONATE_KEY, true);
      yield put(saveImpersonateCustomerId({
        adminEmail: email,
        customerId: '',
      }));
    }

    yield call(handleB2CToken, { accessToken: b2cToken, dispatch });

    return;
  }

  yield call(createLogAuth, {
    dataDogTag: 'RegisterB2C',
    error: response.message,
    httpStatus: response.statusCode,
    mixpanel: MIXPANEL_LOGIN_EVENTS.registerB2CError,
  });

  yield put(forceLogout());
}

export function* doBannerVisibleChanged(_, action) {
  const { payload: { visible } } = action;
  const bannerPreferences = {
    visible,
  };
  yield call(
    saveData,
    BANNER_PREFERENCES_KEY,
    JSON.stringify(bannerPreferences),
  );
}

function* doLoadMultiModalData() {
  const store = yield select(({ policies, session }) => ({
    policies,
    session,
  }));
  const { CustomerId } = store.session.userInfo;
  const policy = store.policies.allPolicies.find((item) => item.Policy.Status
    === POLICY_STATUS.active);

  if (policy) {
    yield put(loadMultiModalData({ CustomerId }));
  }
}

export function* getTemporaryToken(dispatch) {
  yield put(attemptTemporaryToken());
  const response = yield call(
    get,
    dispatch,
    'api/Authentication/TemporaryToken',
  );

  const { IsValid, Data } = response;

  if (IsValid && Data) {
    yield put(setTemporaryToken({ publicToken: Data }));
    yield call(saveData, 'public_token', Data);
    yield put(temporaryTokenCompleted());
  } else {
    yield put(temporaryTokenFailed());
    yield put(showGenericError(true));
  }
}

export function* loadDataPrivateToken(dispatch) {
  yield call(getUserInformation, dispatch);
  yield call(getPolicySummary, dispatch);
  yield call(doLoadMultiModalData, dispatch);
  yield put(privateTokenCompleted());
}

export function* getPrivateToken(dispatch, { payload }) {
  const { publicToken } = payload;
  yield put(attemptPrivateToken());
  const response = yield call(
    apiCallWithApiKey,
    `api/Authentication/TemporaryToken/Validate/${publicToken}`,
    {},
    dispatch,
  );

  const { Data, IsValid } = response;

  if (IsValid && Data) {
    const TOKEN_LEGACY = 'legacy';
    const CUSTOMER_LEGACY = 'customer';

    yield put(setPrivateToken({ privateToken: Data }));

    Data.forEach((tokenScope) => {
      // ignore legacy token
      if (tokenScope.Scope !== TOKEN_LEGACY) {
        if (tokenScope.Scope === CUSTOMER_LEGACY) {
          // use customer token as legacy
          saveData(API_TOKEN, tokenScope.Token);
        }

        saveData(`${tokenScope.Scope}_api_token`, tokenScope.Token);
      } else {
        saveData(API_TOKEN, tokenScope.Token);
      }
    });

    yield call(delay, 100);
    yield put(loadDataForPrivateToken());
  } else {
    yield put(privateTokenFailed());
    yield put(showGenericError(true));
  }
}

export function* convertCustomerId(dispatch, action) {
  const customerId = action.payload;
  const response = yield call(
    apiCallWithApiKey,
    `api/Customer/CustomerIdDecrypter/${customerId}`,
    { method: 'POST' },
    dispatch,
  );

  const { Data, IsValid } = response;

  if (IsValid && Data) {
    yield put(saveImpersonateCustomerId({
      adminEmail: '',
      customerId: Data,
    }));
  } else {
    yield put(showGenericError(true));
  }
}

export function* acceptTermsOfService(dispatch) {
  const store = yield select(({ session }) => ({ session }));
  const { termsOfServiceVersionId: TermsVersionId } = store.session;
  const response = yield call(
    post,
    dispatch,
    'api/User/AcceptTermOfService',
    { TermsVersionId },
  );
  const { IsValid, Data } = response;

  if (IsValid && Data) {
    yield put(termsOfServiceAccepted());
  } else {
    yield put(showGenericError(true));
  }
}

export function* loadCustomerInformation(dispatch, { payload }) {
  const useCustomerApiV1 = yield useCustomerApiV1ForSagas();
  const { marketingChannelId } = payload;

  const urlService = useCustomerApiV1 ? 'customer' : 'api';
  const url = `${urlService}/`
    + `Customer/CustomerMarketChannelDetail/${marketingChannelId}`;
  const response = yield call(get, dispatch, url);

  if (response.IsValid) {
    yield put(customerInformationLoaded({
      addressPart1: response.Data?.AddressPart1,
      addressPart2: response.Data?.AddressPart2,
      displayName: response.Data?.DisplayName,
      email: response.Data?.Email,
      fullName: response.Data?.FullName,
      id: response.Data?.Id,
      originId: response.Data?.OriginId,
      phoneNumber1: response.Data?.PhoneNumber1,
      phoneNumber2: response.Data?.PhoneNumber2,
    }));
  } else {
    yield put(customerInformationFailed());
  }
}
