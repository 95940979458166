import { all, call, put, select, takeLatest } from 'redux-saga/effects';
import { get, getIDORV2, post } from '../services/api';
import {
  allPetsGrouped,
  loadMultiPolicies,
  loadPoliciesCardInformation,
  loadPoliciesPaymentNumber,
  loadPlanSummarySeeMoreInfoAttempt,
  loadWhatWeCoverInfoAttempt,
  multiModalDataLoaded,
  petsGrouped,
  planSummarySeeMoreInfoLoaded,
  policiesOverviewLoaded,
  policyDetailsBenefitsLoaded,
  policyNotificationsLoaded,
  reimbursementMethodAttempt,
  reimbursementMethodLoaded,
  storeAllPolicies,
  storePolicies,
  validateChangeCoverageFailed,
  validateChangeCoverageSuccess,
  whatWeCoverInfoLoaded,
  waitingPeriodsLoaded,
  getChangeCoveragePreviewError,
  changeCoveragePreviewLoaded,
  rateCoverageSuccess,
  rateCoverageError,
  changeCoverageMultimodalLoaded,
  changeCoverageMultimodalFailed,
  quotePowerUpChanged,
  rateQuoteUpdateAttempt,
  rateQuoteUpdateLoaded,
  submitChangeCoverageSuccess,
  submitChangeCoverageFailed,
  getMultibrandPoliciesLoaded,
  getMultibrandPoliciesError,
  getMultibrandDefaultPolicyLoaded,
  getMultibrandDefaultPolicyError,
  getPolicyDetailLoaded,
  getPolicyDetailError,
  getMultibrandPolicies as GET_MULTIBRAND_POLICIES,
  getMultibrandDefaultPolicy as GET_MULTIBRAND_DEFAULT_POLICY,
  getPolicyDetail as GET_POLICY_DETAIL,
  getOrcaPowerUp as GET_ORCA_POWER_UP,
  validateBreedMultibrand as VALIDATE_BREED_MULTIBRAND,
} from '../actions';
import { getOrcaPowerUp, validateBreedMultibrand } from './orcaInsurance';
import { POLICY_STATUS } from '../constants';
import { sleep } from '../services/utils';
import {
  usePolicyApiV1ForSagas,
  usePolicyApiV1T153269ForSagas,
  usePolicyApiV1T153274ForSagas,
  usePolicyApiV1T153276ForSagas,
  usePolicyApiV1T159611ForSagas,
  usePolicySummaryV1ForSagas,
} from '../services/featureFlagsForSagas';
import { POLICY_API_TOKEN } from '../services/constants';

function getPetPolicies({ policies, petId }) {
  return policies.filter((pet) => (
    pet.PetInformation.PetId === petId
  )).map((pet) => ({ ...pet.Policy, ...pet.BillingInformation }));
}

function* groupPets(policiesData, allPets = false) {
  const pets = [];

  policiesData.forEach(({ PetInformation }) => {
    const isPetGrouped =
      pets.find((pet) => pet.PetId === PetInformation.PetId);

    if (!isPetGrouped) {
      const pet = {
        ...PetInformation,
        policies: getPetPolicies({
          petId: PetInformation.PetId,
          policies: policiesData,
        }),
      };

      pets.push(pet);
    }
  });

  const action = allPets ? allPetsGrouped : petsGrouped;

  yield put(action(pets));
}

export function* getPolicySummary(dispatch) {
  const usePolicySummaryV1 = yield usePolicySummaryV1ForSagas();

  let apiUrl = 'api';

  const existPolicyScope = localStorage.getItem(POLICY_API_TOKEN);

  if (existPolicyScope && usePolicySummaryV1) {
    apiUrl = 'policy';
  }

  const url = `${apiUrl}/PMS/Policy/Summary`;
  const response = yield call(get, dispatch, url);

  if (response.success && response.IsValid) {
    const validPolicies = response.Data.filter((policyData) => (
      policyData.Policy.Number
    ));
    const policiesToUpdate = validPolicies.filter((policyData) => (
      policyData.Policy.Status === POLICY_STATUS.active
      || policyData.Policy.Status === POLICY_STATUS.future
    ));

    yield put(storeAllPolicies(response.Data));
    yield put(storePolicies(validPolicies));
    yield put(loadMultiPolicies(policiesToUpdate));
    yield put(loadPoliciesPaymentNumber());
    yield call(groupPets, validPolicies);
    yield call(groupPets, response.Data, true);
  }
}

export function* getPoliciesPaymentNumber(dispatch) {
  const response = yield call(get, dispatch, 'api/PMS/PolicyInfoAndPet');

  if (response.success && response.IsValid) {
    yield put(loadPoliciesCardInformation(response.Data));
  }
}

export function* loadReimbursementMethod(dispatch, { payload }) {
  const store = yield select(({ billingPayments }) => ({ billingPayments }));

  if (!store.billingPayments.reimbursementMethod) {
    yield put(reimbursementMethodAttempt());
  }

  const response = yield call(
    get,
    dispatch,
    'api/PMS/Claim/GetReimbursementMethod',
    { policyNumber: payload },
  );

  if (response.success) {
    yield put(reimbursementMethodLoaded(response.Data));
  }
}

export function* loadPolicyBenefits(dispatch, { payload }) {
  const usePolicyApiV1 = yield usePolicyApiV1ForSagas();
  const urlService = usePolicyApiV1 ? 'policy' : 'api';

  const response = yield call(
    get,
    dispatch,
    `${urlService}/PMS/Policy/Details/${encodeURI(payload)}`,
  );

  if (response.success) {
    yield put(policyDetailsBenefitsLoaded(response.Data));
  }
}

export function* loadMultiModalData(dispatch, { payload }) {
  const { CustomerId } = payload;

  const usePolicyApiV1 = yield usePolicyApiV1ForSagas();

  let urlService = 'api';

  const existPolicyScope = localStorage.getItem(POLICY_API_TOKEN);

  if (existPolicyScope && usePolicyApiV1) {
    urlService = 'policy';
  }

  const url = `${urlService}/PMS/Policy/GetOptionalBenefits/${CustomerId}`;
  const response = yield call(get, dispatch, url);

  if (response.success && response.IsValid) {
    yield put(multiModalDataLoaded(response.Data));
  }
}

/**
 * Only for Figo pet/policies, according to Defect 163335
 */
export function* loadPoliciesOverview(dispatch) {
  const usePolicyApiV1T153276 = yield usePolicyApiV1T153276ForSagas();

  const urlService = usePolicyApiV1T153276 ? 'policy' : 'api';
  const url = `${urlService}/PMS/Policy/GetPolicyCoverageBalances`;

  const response = yield call(get, dispatch, url);

  if (response.success) {
    yield put(policiesOverviewLoaded(response.Data));
  }
}

export function* loadPolicyNotifications(dispatch, { payload }) {
  const usePolicyApiV1T153274 = yield usePolicyApiV1T153274ForSagas();
  const urlService = usePolicyApiV1T153274 ? 'policy' : 'api';
  const url = `${urlService}/PMS/Policy/Notifications/${encodeURI(payload)}`;
  const response = yield call(get, dispatch, url);

  if (response.success) {
    yield put(policyNotificationsLoaded(response.Data));
  }
}

export function* loadWhatWeCoverInfo(dispatch, { payload }) {
  const { effectiveDate, policyNumber, stateAbbr } = payload;

  yield put(loadWhatWeCoverInfoAttempt());

  const url = 'api/InsuranceProduct/GetWhatWeCoverInfo/'
    + `${stateAbbr}/${effectiveDate}/${policyNumber}`;

  const response = yield call(get, dispatch, url);

  if (response.success && response.IsValid) {
    yield put(whatWeCoverInfoLoaded(response.Data));
  }
}

export function* loadPlanSummarySeeMoreInfo(dispatch, { payload }) {
  const { effectiveDate, stateAbbr, policyNumber } = payload;

  yield put(loadPlanSummarySeeMoreInfoAttempt());

  const url = 'api/Policy/GetInsuranceProductCoverageXPlan';

  const response = yield call(get, dispatch,
    `${url}/${stateAbbr}/${effectiveDate}/${policyNumber}`);

  if (response.success && response.IsValid) {
    yield put(planSummarySeeMoreInfoLoaded(response.Data));
  }
}

export function* getWaitingPeriods(dispatch, { payload }) {
  const { policyNumber } = payload;

  const response = yield call(
    get,
    dispatch,
    `api/PMS/Policy/WaitingPeriods/${policyNumber}`,
  );

  if (response.IsValid) {
    yield put(waitingPeriodsLoaded(response.Data));
  }
}

export function* getChangeCoveragePreview(dispatch, { payload }) {
  const usePolicyApiV1T153269 = yield usePolicyApiV1T153269ForSagas();

  const existPolicyScope = localStorage.getItem(POLICY_API_TOKEN);
  const urlService =
    existPolicyScope && usePolicyApiV1T153269 ? 'policy' : 'api';

  const url = `${urlService}/PMS/Policy/ChangeCoverageInformation`;

  const response = yield call(
    get,
    dispatch,
    `${url}/${payload.policyNumber}`,
  );

  if (response.success && response.IsValid) {
    yield put(changeCoveragePreviewLoaded(response.Data));
  } else {
    yield put(getChangeCoveragePreviewError());
  }
}

export function* rateCoverage(dispatch, { payload }) {
  const usePolicyApiV1T159611 = yield usePolicyApiV1T159611ForSagas();

  const existPolicyScope = localStorage.getItem(POLICY_API_TOKEN);
  const urlService =
    existPolicyScope && usePolicyApiV1T159611 ? 'policy' : 'api';

  const { policyNumber, modifiers } = payload;

  const url = `${urlService}/Policy/CoverageChangeRate/${policyNumber}`;

  const response = yield call(
    post,
    dispatch,
    url,
    { modifiers },
  );

  if (response.success && response.IsValid) {
    yield put(rateCoverageSuccess(response.Data));
  } else {
    yield put(rateCoverageError());
  }
}

export function* validateChangeCoverage(dispatch, { payload }) {
  const usePolicyApiV1T153269 = yield usePolicyApiV1T153269ForSagas();
  const urlService = usePolicyApiV1T153269 ? 'policy' : 'api';
  const apiMethod = usePolicyApiV1T153269 ? get : getIDORV2;
  const { policyNumber } = payload;

  const response = yield call(
    apiMethod,
    dispatch,
    `${urlService}/PMS/Policy/ValidateChangeCoverage/${policyNumber}`,
  );

  if (response.IsValid && response.Data) {
    yield put(validateChangeCoverageSuccess({
      isValid: response.Data.Valid,
    }));
  } else {
    yield put(validateChangeCoverageFailed({
      isValid: !!response.Data?.Valid,
      message: response.Message,
    }));
  }
}

export function* getChangeCoverageMultimodal(dispatch, { payload }) {
  const { policyNumber } = payload;
  const url = `api/InsuranceMultiModal/GetAllByPolicyNumber/${policyNumber}`;
  const response = yield call(get, dispatch, url);

  if (response.success && response.IsValid) {
    yield put(changeCoverageMultimodalLoaded(response.Data));
  } else {
    yield put(changeCoverageMultimodalFailed());
  }
}

export function* updatePowerUp(dispatch, { payload }) {
  const { id, isSelected, modifiers, policyNumber } = payload;

  try {
    yield put(quotePowerUpChanged({ id, isSelected }));
    yield put(rateQuoteUpdateAttempt({ id, isSelected }));

    yield call(rateCoverage, dispatch, {
      payload: { modifiers, policyNumber },
    });
    sleep(800);
    yield put(rateQuoteUpdateLoaded({ id, isSelected }));
  } catch (_) {
    yield put(rateQuoteUpdateLoaded({ id, isSelected: !isSelected }));
  }
}

export function* submitChangeCoverage(dispatch, { payload }) {
  const { body, policyNumber } = payload;
  const url = `api/Policy/CoverageChange/${policyNumber}`;

  const response = yield call(
    post,
    dispatch,
    url,
    body,
  );

  if (response.success && response.IsValid) {
    yield put(submitChangeCoverageSuccess());
  } else {
    yield put(submitChangeCoverageFailed());
  }
}

export function* getMultibrandPolicies(dispatch) {
  const url = 'api/MultiBrand/Customer/PetPolicies';
  try {
    const response = yield call(get, dispatch, url);
    if (response.success && response.IsValid) {
      yield put(getMultibrandPoliciesLoaded(response.Data));
    } else {
      yield put(getMultibrandPoliciesError());
    }
  } catch (e) {
    yield put(getMultibrandPoliciesError());
  }
}

export function* getMultibrandDefaultPolicy(dispatch, { payload }) {
  const petId = payload;
  const url = `api/MultiBrand/Customer/DefaultPolicy/${petId}`;
  try {
    const response = yield call(get, dispatch, url);

    if (response.success && response.IsValid) {
      yield put(getMultibrandDefaultPolicyLoaded(response.Data));
    } else {
      yield put(getMultibrandDefaultPolicyError());
    }
  } catch (e) {
    yield put(getMultibrandDefaultPolicyError());
  }
}

export function* getPolicyDetail(dispatch, { payload }) {
  const { policyId } = payload;
  const url = `api/MultiBrand/Customer/PolicyDetail/${policyId}`;
  try {
    const response = yield call(get, dispatch, url);

    if (response.success && response.IsValid) {
      yield put(getPolicyDetailLoaded(response.Data));
    } else {
      yield put(getPolicyDetailError());
    }
  } catch (e) {
    yield put(getPolicyDetailError());
  }
}

export function* policiesSaga(store) {
  yield all([
    takeLatest(GET_MULTIBRAND_POLICIES, getMultibrandPolicies, store.dispatch),
    takeLatest(
      GET_MULTIBRAND_DEFAULT_POLICY,
      getMultibrandDefaultPolicy,
      store.dispatch,
    ),
    takeLatest(GET_POLICY_DETAIL, getPolicyDetail, store.dispatch),

    // ORCA POLICIES
    takeLatest(GET_ORCA_POWER_UP, getOrcaPowerUp, store.dispatch),
    takeLatest(
      VALIDATE_BREED_MULTIBRAND,
      validateBreedMultibrand,
      store.dispatch,
    ),
  ]);
}
